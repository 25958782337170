.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gap5{
  gap:5px;
}
.flex-jst-alg-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-title.MuiTypography-root {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #525f80;
}

.custom-menu-dropdown.MuiButtonBase-root.MuiButton-root {
  padding: 10px;
  width: 100%;
  display: flex;
  background: transparent;
  justify-content: space-between;
  border: 0.5px solid #c4c4c4;
  margin: 10px 0;
  border-radius: 2px;
  align-items: center;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #393939;

  .MuiInputBase-input.MuiOutlinedInput-input {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #393939;
  }
}

.tabs-container {
  margin-top: -10px;

  .MuiBox-root {
    border-bottom: none;
    padding: 0;
  }

  .MuiTabs-scroller {
    background-color: #5267ff;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root.MuiTab-root {
      color: #fff;
      font-family: 'Inter';
      font-weight: 500;
      font-size: 12px;
      padding: 10px;
      line-height: 15px;
    }

    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
  }

  .MuiTabs-indicator {
    background-color: none;
  }
}

.cell-style {
  border: 0.5px solid #c4c4c4;
  border-radius: 6px;
  padding: 2% 4%;
  cursor: pointer;
  margin: 5px 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

input.custom-datepicker {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 10px 4px;
  border: 0.5px solid #c4c4c4;
  border-radius: 2px;
  line-height: 15px;
  color: #393939;
  width: 100%;
}

.text-style.MuiTypography-root {
  font-family: 'Inter';
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
}

.filter-btn.MuiButtonBase-root.MuiButton-root {
  background: #5267ff;
  border-radius: 2px;
  color: #fff;
  text-transform: capitalize;
  margin-right: 6px;
  font-size: 12px;
}

.filter-btn.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  text-transform: capitalize;
}

.overlay {
  background: rgba(82, 103, 255, 0.55);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 10px 24px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.autofill-btn.MuiButtonBase-root.MuiButton-root {
  background: #ffffff;
  padding: 3px 5px;
  border-radius: 2px;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #5267ff;
  margin-left: 100px;
}

.inboxMainContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.inboxChatConatiner {
  display: flex;
}

.cursor-pointer {
  cursor: pointer;
}

.leadContainerBoxStickyIcons {
  padding: 4px 12px;
  border-radius: 16px;
  margin: 0;
  display: inline-block;
  line-height: 10px;
  cursor: pointer;

  // svg {
  //   min-width: 30px;
  // }
}
.right-side-cards .leadContainerBoxStickyIcons:hover,
.right-side-cards .all-border:hover {
  background-color: rgba(82, 103, 255, 0.1);
}
.selected-toolbar .align-middle {
  align-items: center;
}

.mg-top-10px {
  margin-top: 10px;
}

.chatInputContainer {
  display: flex;
  flex-direction: column;
}

.d-flex-jst-space-around {
  display: flex;
  justify-content: space-around;
}

.justify-ct-space-between {
  justify-content: space-between;
}

.headerContainer {
  display: flex;
}

.ft-size-16px {
  font-size: 16px;
}

.nunito-14px-600 {
  font-size: 14px;
  font-family: Nunito;
  font-weight: 600;
}

.inter-12px-500 {
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 500;
}

.inter-12px-400 {
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 400;
}
.inter-12px-600 {
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 600;
}
.text-color-red {
  color: #eb5757;
}
.inter-500px-16px {
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
}
.inter-600px-16px {
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
}
.d-flex-just-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-column-just-align-center {
  display: flex;
  width: 172px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.gap10{
  gap: 10px;
}
.d-flex-align-center {
  display: flex;
  align-items: center;
}

.arrowbox {
  padding: 0;
  margin: 0;
  padding-left: 26px;
  padding-right: 5.5px;
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    border-left: 25px solid transparent;
    border-top: 26px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 0;
    position: absolute;
    left: 0px;
  }

  &:after {
    content: '';
    border-left: 26px solid transparent;
    border-top: 26px solid transparent;
    border-bottom: 26px solid transparent;
    border-right: 0;
    position: absolute;
    right: 0px;
    top: 0;
  }
}

.arrowbox li {
  border: 1px solid #babbbd;
  margin-top: 24px;
  list-style-type: none;
  display: inline-block;
  margin-right: 30px;
  height: 50px;
  line-height: 50px;
  // width: ;
  background: #aaaaaa;
  font-size: 18px;
  text-align: center;
  position: relative;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  font-size: 11px;
  font-weight: 400;

  &::before {
    content: '';
    border-left: 25px solid transparent;
    border-top: 25px solid #aaaaaa;
    border-bottom: 25px solid #aaaaaa;
    border-right: 0;
    position: absolute;
    left: -25px;
  }

  &::after {
    content: '';
    border-left: 25px solid #aaaaaa;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 0;
    position: absolute;
    right: -24px;
  }

  &:hover {
    background: white;
    color: #000;
  }
}

.arrowbox li.active {
  background: white;

  &::before {
    border-left: 25px solid transparent;
    border-top: 25px solid white;
    border-bottom: 25px solid white;
    color: #000;
  }
}

.arrowbox li:hover::before {
  border-left: 25px solid transparent;
  border-top: 25px solid white;
  border-bottom: 25px solid white;
  color: #000;
}

.arrowbox li.active::after,
.arrowbox li:hover::after {
  border-left: 25px solid white;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  color: #000;
}

.arrowbox li.activeLead {
  background: #5267ff;
}

.arrowbox li.activeLead::before {
  border-top: 25px solid #5267ff;
  border-bottom: 25px solid #5267ff;
}

.arrowbox li.activeLead::after {
  border-left: 25px solid #5267ff;
}

.d-flex-row {
  display: flex;
  flex-direction: row;
}
.stages-title{
  color: #42526E;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #FFF;
}

.leadListMain {
  width: 15%;
  border-radius: 8px;
  overflow: auto;
  height: 90vh;
  min-width: 195px;
}

.tabs {
  display: flex;
  background-color: rgba(82, 103, 255, 0.1);
  padding: 0 6px;
  border-radius: 0.25rem;
  width: max-content;
  position: relative;
}

.tab {
  color: #5267ff;
  position: absolute;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  font-size: 0.75rem;
}

.tab-text {
  padding: 0.7rem 0.625rem;
}

.tab-highlight {
  height: 3px;
  width: 100%;
  background-color: #5267ff;
  border-radius: 0.25rem 0.25rem 0 0;
}

.wrapper {
  position: relative;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.disabled {
  opacity: 0.45;
  pointer-events: none;
  z-index: 9999;
}

.inboxChatNdHeader {
  width: 100%;
  position: relative;
  margin: 0 0.625rem;
  max-height: 90vh;
  z-index: 110;
  border-radius: 8px;
  transition: width 0.25s ease-out;
  transition-delay: 40ms;
  background: var(chatContainerBackground);
  // background: linear-gradient(119.13deg, rgba(196, 196, 196, 0.5) -5.09%, rgba(196, 196, 196, 0) 99.92%, #FFFFFF 99.92%),
  //     linear-gradient(0deg, #F8F8F8, #F8F8F8);

  .v1-kanban-tab {
    position: relative;
    padding: 0.625rem;
    background-color: #f8f8f8;
    border-radius: 8px 8px 0 0;
    > div {
      &:first-child {
        &:empty {
          display: none;
        }
      }
    }
  }
}
.inboxChatNdHeaderLead {
  width: 75%;
}

.inboxChatAndHeaderReduced {
  width: calc(100% - 440px - 30px);
}


.leadContainerBoxLead {
  width: 25% !important;
}

.leadContainerBox {
  width: 22% !important;
  overflow: auto;
  height: 90%;
  min-width: 250px;
}

.leadContainerBox-dark {
  width: 22% !important;
  overflow: auto;
  height: 90%;
  min-width: 250px;
}
.chatContainer {
  height: 86%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0 0 8px 8px;
}

.chatInputBox {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.chatContainerBox {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  height: 70%;
  position: relative;
  max-height: 80vh;
}

.align-items-center {
  align-items: center;
}

.leadChatCard {
  font: 14px Inter;
  cursor: pointer;
  border-bottom: 1px solid rgb(235, 235, 235);
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: all 0.35s ease-in;
  .dedupdIcon {
    position: absolute;
    bottom: 7px;
    right: 35px;
    opacity: 0.1;
    svg {
      max-width: 100%;
      height: 21px;
      width: 21px;
    }
  }
}

.disable-click {
  pointer-events: none;
}

// .bg-Selected-lgBlue {
//   background-color: rgba(82, 103, 255, 0.1) !important;
// }

.leadCard-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  color: #525f80;
  margin-top: 5px;
  border-radius: 8px;
}


.leadChatStage {
  position: relative;
  top: 0;
  background: rgba(82, 103, 255, 0.1);
  border-radius: 8px 0;
  max-width: 46%;
  width: fit-content;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 5px;
  color: #5267ff;
}

.lead-date {
  color: rgba(57, 57, 57, 0.7);
  position: absolute;
  right: 3%;
  font-size: 10px;
  top: 2%;
  padding: 5px;
}

.lead-property-name {
  color: rgba(57, 57, 57, 0.7);
  font-size: 12px;
  margin-top: 6px;
}

.lead-agentName {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #393939;
  text-transform: capitalize;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lead-status {
  width: 10px;
  height: 10px;
  background-color: rgba(57, 57, 57, 0.5);
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  right: 5%;
}

.status-processing {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
  right: 6px;
  bottom: 0.75rem;
}

.pd-left-5px {
  padding-left: 5px;
}

.pd-right-5px {
  padding-right: 5px;
}

.pd-6px {
  padding: 6px;
}
.pd-5px {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.chatMessageBody {
  display: flex;
  max-width: 420px;
  min-width: 210px;
  padding: 10px;
  align-items: center;
  border-radius: 8px 0 8px 8px;
  background: rgba(186, 161, 247, 0.3);

  width: fit-content;
  position: relative;

  div:not(.date-time) {
    font-family: 'Inter';
  }
}

.chat-message-container {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.sender-initial {
  height: 30px;
  width: 30px;
  background: #C4C4C44D;
  fill: rgba(196, 196, 196, 0.30);
  filter: drop-shadow(0px 2px 4px rgba(230, 221, 253, 0.20));
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  margin: 0px 10px;
}

.chat-message-container-no-events{
  margin-top: 62px;
}

.chat-message-container-events{
  margin-top: 62px;
}

.sms-email-option {
  display: flex;
  justify-content: space-around;
  color: #42526e33;
  text-align: center;
  cursor: pointer;

  div {
    padding: 15px 8px;
    width: 50%;
    border-top: 3px solid transparent;
  }

  .active-option {
    color: #42526e;
    position: relative;
    top: 0;
  }

  .active-option::after {
    content: '';
    position: absolute;
    background-color: transparent;
    height: 3px;
    top: -6px;
    left: 0;
    width: 100%;
    border-bottom: 4px solid #5267ff;
    border-radius: 0 0 4px 4px;
  }
}

.more-chat-options {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 93%;
  padding: 0px 10px;
}

.customer-msg {
  justify-content: flex-end;
  flex-direction: row-reverse;

  .chatMessageBody {
    background-color: #fff;
  }
}

.agent-msg {
  justify-content: flex-end;
}

.stage-container {
  max-height: 2rem;
  padding: 0 0.625rem;
  border-radius: 0.5rem;
  text-align: center;
  transition: max-height 0.2s ease-in-out;
  min-width: 170px;
  position: absolute;
}
.stage-container-expanded {
  min-width: 170px;
  padding: 0.625rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 3px 4px 11px 0px rgba(0, 0, 0, 0.10);
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FFF;
  max-height: 550px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.kanban-item {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 110px;
  background: #F8F8F8;
  border-radius: 4px;
}
.kanban-list-item {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 110px;
  background: #FFF;
  border-radius: 4px;
}
.selected-stage {

  color: #67C848;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Inter;
  border: 1px solid;
  border-image: linear-gradient(180deg, #67C848 -17.14%, rgba(225, 244, 218, 0) 100%);
  border-radius: 4px;
}

.kanban-container{
  position: relative;
  z-index: 99;

}
.selected-item-wrapper{
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(180deg, #67C848 -17.14%, rgba(225, 244, 218, 0) 100%);
}

.expanded-view{

    position: absolute;
    background-color: white;
    z-index: 1;
    top:15px;

}

.owner-container {
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(119.13deg, rgba(196, 196, 196, 1) -5.09%, rgba(57, 57, 57, 0) 99.92%);

}

.owner-name {
  padding: 10px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #393939;
  background-color: #f8f8f8;
  border-radius: 4px;
  display: flex;
  gap: 10px;
}

.gif-options {
  padding: 4px 12px;
  background: #fff;
  height: 5vh;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  flex-grow: 1;
  justify-content: inherit;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
}

.right-side-cards {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  margin-top: 10px;
  font-family: 'Inter';
}

.ai-settings {
  transition: all 150ms linear;
  overflow-y: hidden;
  padding: 0 4px;
}

.bot-settings__open {
  height: 130px;
}

.bot-settings__close {
  height: 0;
  padding-bottom: 0;
}

.selected-toolbar {
  opacity: 1 !important;
  animation: pulse 400ms;
  background-color: #5267ff !important;

  color: #fff !important;
  border: 1px solid #5267ff;
}

@keyframes pulse {
  0% {
    background-color: #fff;
    color: #000;
  }
  100% {
    background-color: #5267ff;

    color: #fff;
    border: 1px solid #5267ff;
  }
}
.tour-schedule-popup {
  animation: pulse-popup 600ms;
  transition-timing-function: linear;
}
@keyframes pulse-popup {
  0% {
    top: 5%;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

.customModalCont.zoom-transition {
  animation: zoom-in 300ms;
  width: 376px;
  transition-timing-function: linear;
}
@keyframes zoom-in {
  0% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.customModalCont.error-popup {
  animation: error 500ms;

  transition-timing-function: linear;
}
@keyframes error {
  0% {
    transform: scale(0.5, 0.5);
  }
  25% {
    transform: scale(1.3, 1.3);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
}
.inter-600px-14px {
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
}

.inter-500px-14px {
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
}

.inter-500px-12px {
  font-family: Inter;
  font-size: 0.75rem;
  font-weight: 500;
}

.color-525F80 {
  color: #525f80;
}

.ai-bot-settings {
  position: relative;
  border: 0.5px solid #e2e2e2;
  border-radius: 3px;
  padding: 4px 10px 0 10px;
  margin: 10px 0;
}

.inactive-bot {
  position: absolute;
  right: 4%;
  background: rgba(255, 77, 0, 0.1);
  border-radius: 2px;
  padding: 0px 5px;
  color: #ff4d00;
}

.active-bot {
  position: absolute;
  right: 4%;
  background: #d7efe0;
  border-radius: 2px;
  padding: 0px 5px;
  color: #4caf50;
}

.input-comment {
  width: 95%;
  border: 0.5px solid #c4c4c4;
  height: 35px;
}

.input-sec {
  padding: 5px;
}

.template-name {
  padding: 5px 0px 5px 10px;
  cursor: pointer;
}

.lead-filter-options {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 12px;
  position: sticky;
  top: 0;
  z-index: 10;
  color: #42526e;

  & > div {
    position: relative;
    display: flex;
    align-items: center;

    img {
      position: absolute;
      right: 8px;
    }
  }
}

.MuiFormLabel-asterisk {
  color: red;
}

.filter-popoup {
  .MuiPaper-elevation8 {
    padding: 10px;
    top: 14% !important;
    left: 30% !important;
  }

  .MuiInputLabel-formControl {
    top: -32%;
    left: 5px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #000;
    left: 12px;
    top: -10%;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}
.lead-filter-popoup {
  .MuiPaper-elevation8 {
    padding: 10px;
    top: 32% !important;
    left: 41% !important;
    width: 251px;
  }

  .MuiInputLabel-formControl {
    top: -32%;
    left: 5px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #000;
    left: 12px;
    top: -10%;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #d9d9d9;
  }
}

.building-filter-popoup {
  .MuiPaper-elevation8 {
    padding: 10px;
    top: 15% !important;
    left: 80% !important;
    width: 251px;
  }

  .MuiInputLabel-formControl {
    top: -32%;
    left: 5px;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #000;
    left: 12px;
    top: -10%;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #d9d9d9;
  }
}

.sub-title.MuiTypography-root {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
}

.card-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0;
  padding: 0px !important;
}

.custom-select {
  width: 160px;
}

.figure {
  text-align: center;
  margin: 0px;
}

.internal-message {
  background-color: #e6ddfd;
  padding: 0;
}

.tour-deleted {
  background-color: #ffc9b2 !important;
}

.tour-scheduled {
  background-color: #e1f4da;
}
.waitlist-bg{
   background: #CFEAFF;
   min-width: 310px !important;
}
.zuma-agent {
  background-color: #c8f0f7;
  padding: 0;
}

.customer-message {
  border-radius: 0 8px 8px;
  padding: 0;
}

.email-separator {
  border: 0.4px solid #D9D9D9;
  opacity: 0.4;
}

.email-message {
  overflow: hidden;
  margin: 0 !important;
  border-radius: 8px;
  max-width: 451px;
  min-width: 400px;
  .read-moreEmail {
    cursor: pointer;
    text-align: end;
  }
}
.email-incoming{
  border: 1px solid rgba(196, 196, 196, 0.50);
  background: #FFF;
}
.email-outgoing{
  border-radius: 8px;
  background: #5559DF;
  color:#FFF !important;
}
.message-owner {
  position: absolute;
  top: -22px;
  right: 50px;
  text-align: center;
}

.message-owner-customer {
  position: absolute;
  top: -22px;
  left: 50px;
  text-align: center;
}

.read-moreEmail {
  border: 1px solid #393939;
  width: fit-content;
  cursor: pointer;
  text-align: end;
  padding: 4px;
  border-radius: 4px;
}

.outgoing-readMoreEmail{
  border: 1px solid #FFF !important;
}

.CRM-background {
  background-color: #ffc8dd;
}

.PMS-background {
  background-color: #b4ceee;
}

.date-time {
  position: absolute;
  bottom: -17px;
  right: 1%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.date-time-sync{
  color: #C4C4C4;
  text-align: left;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nunito-10px-400 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
}

.inter-10px-400 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px !important;
  line-height: 12px;
}

.lead-cards,
.call-message {
  div {
    padding-top: 10px;
  }
}

.lead-details-color {
  color: rgba(57, 57, 57, 0.7);
}
.test-class{
  color: #fff
}
.filter-applied {
  width: 5px;
  height: 5px;
  position: absolute;
  right: 20%;
  background: #fff;
  border-radius: 50%;
  top: 24%;
}

.filter-length {
  position: absolute;
  background: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  padding: 4px;
  line-height: 10px;
  color: #5267ff;
  height: 10px;
  width: 10px;
  right: 3px;
  border-radius: 50%;
  z-index: 999999;
}

.inter-16px-500 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #525f80;
}

.inter-12px-500 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #415064;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  border-radius: -1px;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  height: 2px;
}

.filter-dot {
  width: 6px;
  height: 6px;
  background: #5267ff;
  position: absolute;
  border-radius: 50%;
  right: 4%;
  top: -14%;
}

.mr-10 {
  margin-right: 2%;
}

.ml-0 {
  margin-left: 0;
}

// text colors

.cheat-sheet {
  margin-top: 15px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
}

.status-container {
  padding: 5px 10px;
  margin: 0 -10px;
  background: #eef0ff;
  border: 0.5px solid #eef0ff;
  border-radius: 0px 0px 2px 2px;
}

.tab-border {
  width: 29px;
  height: 2px;
  left: 25px;
  position: absolute;
  top: 34px;
  background: #ffffff;
  border-radius: 2px;
}

// text colors

.grey-text {
  color: #42526e;
}

.label-text-listing {

}


.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.all-border {
  font-size: 14px;
  padding: 7.5px 14px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  color: #42526e;
}

.opacity-50 {
  opacity: 0.5;
}

.color-393939 {
  color: #393939;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 4%) !important;
}

textarea {
  border: none;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 5px 10px;
}

.chatContainerInside {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 30px;
}

.custom-menu-dropdown {
  .MuiOutlinedInput-root.MuiInputBase-root {
    padding-left: 0;
  }

  .MuiAutocomplete-input,
  .MuiInputBase-input.MuiOutlinedInput-input {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #393939;
  }
}

.preferred-community-dropdown {
  .MuiOutlinedInput-notchedOutline {
    border: #d9d9d9;
  }
  input[type='text'] {
    border: none;
  }
  .MuiAutocomplete-option {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #393939;
  }
}

.MuiAutocomplete-option {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  padding: 4px;
  color: #393939 !important;
}

.custom-autocomplete,
.custom-property-dropdown {
  .MuiAutocomplete-option {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #393939;
  }
}

.price-modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #525f80;
}

.price-modal-price.MuiTypography-root {
  padding: 10px;
  color: #fff;
  background: linear-gradient(
    273.14deg,
    rgba(82, 103, 255, 0.98) 0.87%,
    rgba(22, 51, 255, 0.84) 84.57%
  );
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  text-align: center;
}

.price-modal-key.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
}

.price-modal-value.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(57, 57, 57, 0.7);
}

.slider-title.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #393939;
}

.slider-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #525f80;
}

.slider-container {
  width: 184px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #5267ff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 12px 12px;
  position: absolute;
  top: 50%;
  left: 14%;
}

.page-name {
  color: #393939;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  width: 300px;
}

.agent-status {
  position: absolute;
  box-sizing: border-box;
  bottom: -1px;
  right: -1px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  z-index: 3;
  border: 1px solid #F3F6FB;
}

.agent-status-indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.user-controls-container { 
  position: absolute;
  top: 40px;
  right: 0;
  display: none;
}

.user-controls-container.open {
  display: block;
}

.price-container {
  align-items: flex-start;
  padding: 10px 10px 40px 10px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.custom-property-dropdown {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #393939;
}

.answer-text-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5267ff;
}

.active-question {
  padding: 5px;
  background: rgba(82, 103, 255, 0.1);
  border-radius: 4px;
}

.add-icon-container {
  background: #42526e;
  border-radius: 50%;
  display: inline-block;
}

.filter-sub-heading.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
}

.css-12jo7m5 {
  color: #5267ff !important;
}
.kelsey-popup {
  font-family: 'Inter';

  .MuiCardContent-root {
    padding: 20px;
    position: relative;

    .kelsey-popup-header {
      color: #42526e;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
    .MuiFormControl-root,
    .MuiFormControl-root {
      margin: 0 0 20px 0;
      width: 100%;
    }
    .kelsey-popup-dropdown-label {
      display: inline-block;
      padding-bottom: 10px;
      font-weight: 500;
      color: #393939;
      font-family: 'Inter';
      font-size: 12px;
    }
    .save-btn,
    .disable-btn {
      background: #5267ff;
      border-radius: 2px;
      font-size: 12px;
      color: #fff;
      font-family: 'Inter';
    }
    .disable-btn {
      background: rgba(82, 103, 255, 0.5);
    }
    .clear-btn {
      color: #393939;
      font-size: 12px;
    }
  }
  .MuiSelect-select {
    font-size: 12px;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.kelsey-popup-dropdown-menu {
  font-size: 12px !important;
}

.line {
  margin-top: 5px;
  border: 0.4px solid #6ae9ff;
  width: 99%;
  position: absolute;
  left: 0px;
  // top: 43%;
}

.line2 {
  margin-top: 5px;
  border: 0.4px solid #6ae9ff;
  width: 99%;
  position: absolute;
  left: 0;
  top: 13%;
}

.comment-container {
  margin: 6px;
  padding: 6px;
  background: rgba(82, 103, 255, 0.05);
  .date {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #525f80;
  }
}
.tour-notes-event {
  background-color: #c8f0f7;
}

.chatMessageBody {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  padding:10px;
}
.callNotesGrid{
  display: grid;
  grid-template-rows: auto;
}

.subText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
}

.notes-popup-border {
  background-color: rgba(82, 103, 255, 0.1);
  border: 0.5px solid #5267ff;
  color: rgba(82, 103, 255, 1);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: max-content;
  div {
    padding: 6px;
  }
  .selected {
    background-color: rgba(82, 103, 255, 1);
    color: #fff;
  }
}

.message-text {
  color: #393939;
  font-size: 12px;
  overflow-wrap: anywhere;
}
.outgoing-message{

  color:#FFF !important;

}
.manual-popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.25);
  .popup-body {
    padding: 10px;
    background-color: #fff;
  }
}


.ql-toolbar.ql-snow {
  border: none !important;
}

.commonPopUpStyle {
  h6 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 16px;
    color: #42526e;
  }
  .closeContainer {
    position: relative;
    svg {
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 16px;
      color: #443e3e;
    }
  }
  .btnContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .MuiCardContent-root {
    min-width: 300px !important;
  }
}
.pop-background-btn,
.pop-transparent-btn {
  min-width: 40px !important;
  padding: 6px 10px !important;
  margin: 0 !important;
  box-sizing: border-box !important;
  background-color: #5267ff !important;
  color: #ffffff !important;
  font-weight: 500;
  text-transform: none !important;
  &.pop-background-disable {
    opacity: 0.4;
  }
}
.pop-transparent-btn {
  background: transparent !important;
  color: #393939 !important;
  box-shadow: none !important;
}

tfoot {
  width: 240px;
  tr {
    width: inherit;
  }
}
.filter-empty-cont {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #393939;
  p {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(57, 57, 57, 0.7);
    margin-left: 10px;
    line-height: 16px;
  }
}
.pointerRemoved {
  pointer-events: none !important;
}
.manual-popup.customModalCont {
  .popup-body {
    background-color: #fff;
    width: 376px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 4%) !important;
    position: relative;
    label {
      font-weight: 600;
      font-size: 14px;
      color: #393939;
      font-family: 'Inter';
      margin-bottom: 10px;
    }
    svg {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    button {
      text-transform: none;
      &.save-btn {
        background: #5267ff;
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        color: #ffffff;
        padding: 8px 24px;
      }
      &.disable-btn {
        opacity: 0.4;
      }
    }
  }
}
.manual-popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 999;
}
.save-btn.MuiButtonBase-root.MuiButton-root {
  &.mr-0 {
    margin-right: 0 !important;
  }
}
.archive-box {
  border: 0px solid #67c848;
  display: flex;
  position: absolute;
  top: 50%;
  width: auto;
  min-width: 217px;
  height: 47px;
  overflow: hidden;
  box-sizing: border-box;
  left: 50%;
  border-radius: 3px;
  align-items: center;
  transition: all ease 0.2s;
  transform: scale(0) translate(-50%, 0px);
  display: flex;
  opacity: 0;
  justify-content: space-evenly;
  p {
    margin: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #67c848;
    padding-left: 10px;
  }
  &.archive-visible {
    opacity: 1;
    transform: scale(1, 1) translate(-50%, 0px);
    z-index: 99;
    padding: 10px;
    transition: all ease 0.1s;
    border: 1px solid #67c848;
  }
}

.archive-confirm {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  animation: fadeIn 0.25s ease-in 1;
  animation-delay: 0;
}

.interaction-container {
  height: 305px;
  width: 305px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: scaleUp 0.25s ease-in 1;
  animation-delay: 0;
}

.archive-msg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding: 15px 24px;
  background-color: #dfede8;
  color: #00875a;
  border-radius: 4px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.15s ease-in;
  transition-delay: 0s;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
}

.date-custom-disable {
  opacity: 0.5;
  background: rgb(255 255 255 / 60%);
  pointer-events: none;
}
.date-box-custom {
  position: relative;
  height: 17px;
}
.over-Scroll {
  overflow-y: scroll;
}

.call-event-body {
  border-top: 0.4px solid #6ae9ff;
}
.micro-widget {
  right: 0;
  top: 15%;
  bottom: 0;
  pointer-events: none;
  width: 22%;
}

.leads-fade-out {
  left: 0;
  width: 22%;
  top: 15%;
  bottom: 0;
  pointer-events: none;
}
.hide-top-bar {
  top: 0;
  /* width: 93%; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  opacity: 0.25;

}
.lead-filter-options div {
  position: relative;
}
.font-12px div input {
  font-size: 12px !important;
  width: 10px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
}

.send-btn {
  margin-left: 0.8%;
  position: absolute;
  right: 0;
  bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  &:hover {
    transform: scale(1.25);
  }
}

.sentiment-wrapper {
  z-index: 10;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sentiment-container {
  grid-column: 1;
  grid-row: 1;
  width: 300px;
  transform: scaleX(0.6);
  transition: all 0.55s ease-out ;
  visibility: hidden;
}

.sentiment-pill-wrapper {
  grid-row: 1;
  grid-column: 1;
}

.sentiment-scaleup {
  transform: scaleX(1);
  visibility: visible;
}
//ANIMATIONS BEGIN

@media(max-height: 1080px) {
  :root {
    --slide-up-y: -390px
  }
}

@media(max-height: 900px) {
  :root {
    --slide-up-y: -317px
  }
}

.fade-out {
  animation: fadeOut 0.25s ease-out 1 normal;
}

.scale-down {
  animation: scaleDown 0.25s ease-out normal;
  animation-duration: 0.50s;
}

.scale-up {
  animation: scaleUp 0.25s ease-out normal;
}

.waitlist-container{
  position: absolute;
}
.bottom-fade-out {
  animation: alternate 0.5s bottomFadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    // max-height: 121px;
    scale: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    max-height: 0;
    scale: 0;
    margin-bottom: 0;
    visibility: hidden;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: none;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes scaleUp {
  0% {
    scale: 0;
    visibility: none;
  }

  100% {
    scale: 1;
    visibility: visible;
  }
}

@keyframes scaleDown {
  0% {
    scale: 1;
    visibility: none;
  }

  100% {
    scale: 0;
    visibility: visible;
  }
}

@keyframes bottomFadeOut {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(var(--slide-up-y));
  }
}

.loader {
  position: absolute;
  left: 38%;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #5267ff; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
}

.archive-loader {
  // position: absolute;
  // left: 38%;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #5267ff; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

#tl-btn__format {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  transition: all 150ms ease-in;
  margin: 15px 5px;
  &:hover {
    background-color: #c4c4c433;
  }
}

#tl-btn__input {
  display: flex;
  justify-content: center;
  border-radius: 4px;
  transition: all 150ms ease-in;
  margin: 15px 5px;
  padding: 0;
  &:hover {
    background-color: #c4c4c433;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mui-date-picker{
  min-width: 260px !important;
  
  .Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #c4c4c4 !important;
  }
}

.waitlist-message-icon {
  color: #42526E;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
margin-right: 10px;
}

.react-datepicker__time-list-item {
  font-weight: 400;
}
.react-datepicker__triangle {
  display: none;
}
.minimised-event-container {
  margin-right: 8px;
  position: relative;
  display: inline-block; /* or inline-flex, depending on your use case */
}
.minimised-event-container:hover .maximised-event-container {
  display: block;
}
.maximised-event-container {
  display: none;
  position: absolute;
  top: 0%;
  left: -750%; 
  transform: translateX(-50%);
  z-index: 100;
}

.knowledge-base-headers {
  background-color: #C4C4C433;
  line-height: 17px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.knowledge-base-row-odd {
  background-color: #C4C4C433;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}
.knowledge-base-row-even {
  background-color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
}

.knowledge-base-cell {
  border: none;
}

.cs-update-description {
  font-size: 14px;
  color: #5267ff; 
  margin-top: 3px;
}

.MuiList-padding{
  padding: 5px !important;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.jc-start {
  justify-content: flex-start;
}

.checkbox-container {
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
  box-sizing: border-box;
}
.vl {
  border-left: 1px solid #000000;
  height: 90px;
}
.communityPhoneContainer {
  height: 700px;
  border-radius: 8px;
}

.communityPhoneTop {
  position: absolute;
  width: 334px;
  height: 44px;
  left: 20px;
  top: 60px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.p-20 {
  padding: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.p-10 {
  padding: 10px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.br-4 {
  border-radius: 4px;
}

.tab-container {
  background-color: #fff;
  border-radius: 4px;
  position: relative;
}

.client-container {
  background: rgba(82, 103, 255, 0.2);
  border-radius: 4px;
}

.pointer {
  cursor: pointer;
}

.active-tab {
  position: absolute;
  width: 100%;
  height: 3px;
  background: #5267ff;
  border-radius: 4px 4px 0px 0px;
  bottom: -20px;
  left: 8px;
}

.switch-settings-label {
  .MuiTypography-root {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 12px;
    color: rgba(57, 57, 57, 0.7);
  }
}

.switch-label {
  .MuiTypography-root {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 12px;
    color: #393939;
  }

  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg-white {
  background-color: #fff;
}

.container-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #42526e;
  margin-top: 40px;
  margin-bottom: 20px;
}

.container-desc {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #393939;
  margin-left: 20px;
  margin-top: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.save-btn.MuiButtonBase-root.MuiButton-root {
  background: #5267ff;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 8px 16px;
}

.clear-btn.MuiButtonBase-root.MuiButton-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #393939;
  padding: 8px 24px;
}

.inter-500-16px {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  color: #393939;
  font-size: 16px;
}
.inter-500-14px {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  color: #393939;
  font-size: 14px;
}

.blue-color-text {
  color: #5267ff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;

  font-size: 14px;
}
.label-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
}

.settings-modal {
  .MuiPaper-root.MuiDialog-paper {
    width: 100%;
  }
}

.settings-tab {
  margin-top: 20px;
  border-radius: 4px;
  background: rgba(82, 103, 255, 0.1);
}

.tabs-style {
  padding: 12px 64px;
  display: inline-block;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #393939;
}

.active-tab-style {
  background: #5267ff;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.li-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #393939;
}

.settings-modal-label {
  .MuiTypography-root {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393939;
  }
}

.list li {
  list-style-type: disc;
}

.list1 li {
  list-style-type: none;
}

.list1 ul {
  justify-content: space-between;
  display: flex;
  margin-left: -22px;
}

.sub-list-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #393939;
}

.settings-card-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.settings-card-style {
  border: 0.5px solid #c4c4c4;
  border-radius: 8px;
  width: 300px;
}

.apartment-style-text {
  background: rgba(82, 103, 255, 0.1);
  border-radius: 4px;
  margin: 0;
}

.settings-text-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #393939;
  margin: 0;
}

.settings-desc-text-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #393939;
}

.apartment-text-style {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: #5267ff;
  padding: 6px;
  display: flex;
  background: rgba(82, 103, 255, 0.1);
  border-radius: 4px;
}

.add-icon-container {
  padding: 55px 20px;
  background: none;
  border: 0.5px solid #c4c4c4;
  border-radius: 8px;
}

.integrationContainer {
  height: calc(100vh - 200px);
  overflow-y: auto;
  position: relative;

  .MuiOutlinedInput-root {
    input {
      border: none;
    }
  }
}

.integration-grid {
  padding: 20px 17px 94px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.selfServeSettings {
  overflow-y: scroll;
  height: auto;
}
.emptyScreenIntegration {
  position: absolute;
  width: 339px;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);

  button {
    width: 100%;
    font-size: 24px;
    font-family: 'Inter';
    height: 72px;
    border-radius: 8px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0px, -50%);
  }
}

.successPopup {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-btn-disable {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}

.form-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  margin: 0;
  margin-bottom: 5px;
  color: #393939;
}

.form-text-field {
  .MuiOutlinedInput-root {
    width: 100%;
    padding-right: 0;

    input {
      border: 0.5px solid #c4c4c4;
      border-radius: 4px;
      height: 44px;
      box-sizing: border-box;
      padding-left: 20px !important;
    }
  }
}

.MuiTableCell-root {
  font-family: 'Inter' !important;
  border-bottom: none !important;
}

.user-length-text {
  width: 24px;
  height: 24px;
  background: #fff;
  padding: 4px 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5267ff;
  border-radius: 50%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pmsContainer {
  .MuiSnackbarContent-root {
    background-color: red;
  }
}

.veryfyContainer {
  position: relative;
  width: 350px;

  fieldset {
    border: none;
  }

  .veryfyIcons {
    position: absolute;
    right: 11px;
    z-index: 999;
    top: 50%;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #5267ff;
  }
}

.user-deletion-text {
  font-family: 'Inter';
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #393939;
}

.user-deleted-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin: 0;
  color: #393939;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}
.customTable {
  > div {
    .MuiTableCell-alignLeft,
    .MuiTableCell-head {
      padding-left: 10px;
    }
  }
}

tr {
  margin-top: 10px !important;
}
.modalLi {
  span {
    font-size: 16px !important;
  }
}
.tabModalCont {
  .tabs-style {
    &.tabModal {
      padding-left: 0;
      padding-right: 0;
      width: 50%;
      text-align: center;
      display: inline-block;
      text-align: center;
    }
  }
}
.crmMod {
  padding-top: 24px;
  height: 70%;
}
.mapHeader {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #393939;
  padding-bottom: 15px;
  padding-top: 15px;
}
.mapRow {
  margin-bottom: 12px;
  display: flex;
  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    color: #393939;
  }
}
.crmBlock {
  border: 0.5px solid #c4c4c4;
  border-radius: 3px;
  padding: 15px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 80px;
  box-sizing: border-box;
  .dFlexLi {
    display: flex;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .spanMedium {
    padding-bottom: 14px;
    display: inline-block;
  }
  > div {
    background: transparent !important;
    align-items: center;
    span {
      margin: 0 !important;
      font-family: 'Inter';
    }
  }
  .MuiOutlinedInput-input {
    font-size: 12px;
  }
  .mappings-new-cont {
    > span {
      min-width: 140px;
    }
    .mappings-new {
      margin-left: 67px !important;
      position: relative;
      * {
        margin-left: 0 !important;
        .MuiSelect-select {
          height: 20px;
          min-height: 1px;
        }
      }
      .mappings-new-inner {
        position: relative !important;
        > svg {
          position: absolute;
          top: -4px;
          right: -4px;
        }
      }
      .mappings-flex {
        > svg {
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          color: rgba(54, 52, 52, 0.71) !important;
          width: 20px !important;
          height: 20px !important;
          position: absolute;
          top: 50%;
          right: -20px;
          transform: translate(0px, -50%);
        }
      }
    }
  }
}
.disablebtn {
  &.save-btn {
    opacity: 0.3;
  }
}
.loaderNew {
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 39%);
  position: fixed;
  z-index: 99999;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-100-left {
  width: 100%;
  padding-left: 12px;
}
.padding-10 {
  padding: 10px;
}
.w-30 {
  width: 30%;
}
.phoneContainer {
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  .inputContainer {
    margin-left: 21px;
    display: flex;
    align-items: center;
    position: relative;
    input[type='text'] {
      width: 146px;
      height: 34px;
      font-size: 12px;
      box-sizing: border-box;
      border: none;
    }

    &:hover,
    &:focus {
      .phoneNumberCont {
        display: block;
      }
    }
  }
  svg {
    cursor: pointer;
  }
}
.phoneNumberCont {
  position: absolute;
  display: none;
  top: 100%;
  width: 146px;
  z-index: 99;
  background: rgb(248, 248, 248);
  .numbers {
    padding: 10px;
    background: rgb(248, 248, 248);
    color: black;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }
  }
}
.actionCont {
  margin-left: 20px;
}

.actionBtns {
  button {
    background: transparent;
    color: #393939;
    box-shadow: none;
    text-transform: none;
    &:hover {
      background: transparent;
      box-shadow: none;
    }
    &.save-btn {
      background: #5267ff;
      padding: 9px;
      box-sizing: border-box;
      min-width: 71px;
    }
  }
}

.client-card {
  background-color: #f6f7ff;
  width: 278px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 10px;
  margin-right: 20px;

  cursor: pointer;
  .client-setup {
    padding-right: 10px;
  }
  .client-count {
    padding-left: 10px;
  }
  .client-name {
    color: #7c7e8a;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 210px;
  }

  .client-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    color: #393939;
    display: flex;
  }
}

.clients-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 590px;
  overflow: scroll;
}
.separator {
  border: 0.5px solid #c4c4c4;
}
.clients-selected {
  display: flex;
  margin-bottom: 20px;
}

.client-name-agent {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  color: #393939;
}

.tenantName {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}
.ant-modal-mask {
  z-index: 1301;
}
.ant-modal-wrap {
  z-index: 1302;
}

body {
  margin: 0px;
  background: #f3f3f3;
  outline: none;
}
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

ul li {
  list-style-type: none;
}

.StepLabel .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  width: 190px;
}

.slick-prev:before {
  color: #cdccec;
}

.slick-next:before {
  color: #cdccec;
}

.MuiPaper-root:focus {
  outline: none;
}

a.RefLink {
  color: #79ca96;
}

.MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable {
  background-color: #ff6f62;
  color: #ffffff;
}

.MuiSvgIcon-root.MuiChip-deleteIcon {
  color: #ffffff;
}

.MuiChip-label {
  color: '#ffffff';
  font-family: 'Inter';
  font-size: 12;
  font-weight: 600;
}

.MuiAutocomplete-option {
  color: #20065f;
  font-size: 16;
  font-weight: 600;
  font-family: 'Inter';
}

.rownav > div:focus {
  background-color: #ffffff;
  color: #20065f !important;
  font-family: 'Inter';
  font-size: xx-large;
  font-weight: bolder;
  outline: none;
  /* text-decoration: underline; */
}

/* .ql-editor.ql-blank {
  min-height: 152px;
}

.ql-editor {
  min-height: 200px;
} */

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-22.MTableToolbar-highlight-23.MuiToolbar-gutters {
  background-color: #ffffff;
}

.MuiToolbar-root.MuiToolbar-regular {
  color: #ffffff !important;
  background: #ffffff !important;
}

.MuiTabs-flexContainer {
  background-color: #5267ff;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
  background: #ffffff;
  color: #20065f;
  font-family: 'Inter';
  font-size: 14;
  font-weight: 600;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
  color: #525f80;
  font-family: 'Inter';
  font-size: 14;
  font-weight: 600;
}

.PrivateTabIndicator-root-34.PrivateTabIndicator-colorSecondary-36.MuiTabs-indicator {
  background: #20065f;
}

.notiftab .MuiTabs-flexContainer {
  background-color: white;
}

#badgenotif
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
  background-color: #ff6f62;
  margin-right: -6px;
  margin-top: 2px;
}

#badgenotif2
  .MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-colorSecondary {
  background-color: #ff6f62;
  margin-right: 4px;
  margin-top: 16px;
}

.scrollCont::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.react-datepicker__month-container {
  width: 262px !important;
}
.react-datepicker {
  width: max-content !important;
}

span.react-datepicker__year-read-view--down-arrow {
  display: none !important;
}

.react-datepicker__header {
  text-align: center !important;
  padding: 8px 0 !important;
  position: relative !important;
  border-bottom: none !important;
  background-color: #fff !important;
}

.react-datepicker__day {
  /* color: #000 !important; */
  /*  background-color: #ff6f62 !important; */
}

.react-datepicker__day--selected {
  border-radius: 15px !important;
  background-color: #ff6f62 !important;
  color: #fff !important;
}

.react-datepicker-time__header {
  color: #23165f !important;
  font-weight: 500 !important;
  letter-spacing: 1px !important;
}

/*  */

.react-datepicker__day-name {
  color: #42526e !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.react-datepicker__day-names {
  background-color: #f2f6fc !important;
  margin: 0 10px !important;
  border-radius: 4px !important;
}

.react-datepicker__year-dropdown {
  background-color: #ffffff !important;
}

.react-datepicker__year-option:hover {
  background-color: #3f948b30 !important;
}

.react-datepicker__day--keyboard-selected {
  /* border-radius: 15px !important; */
  background-color: #ffffff !important;
  /* color: #000 !important; */
}

.react-datepicker__year-option {
  font-size: 14px !important;
  font-family: 'Inter' !important;
}

.react-datepicker__year-option--selected {
  color: #ff6f62 !important;
  font-size: 16px !important;
}

.react-datepicker__current-month {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: 'Inter' !important;
  color: #23165f !important;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  margin-top: -16px !important;
  margin-bottom: 12px !important;
  margin-left: 55px !important;
  font-size: 14px !important;
  color: #23165f !important;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown {
  margin-top: 10px !important;
  margin-right: 50px !important;
}

.react-datepicker__navigation--previous {
  background: #f1f5ff !important;
  border-radius: 50% !important;
  margin-top: 8px !important;
  margin-left: 9px !important;
}

.react-datepicker__navigation--next {
  background: #f1f5ff !important;
  border-radius: 50% !important;
  margin-top: 8px !important;
  margin-right: 9px !important;
}
.MuiDataGrid-main {
  max-height: 600px;
  overflow-y: auto;
}
.MuiDataGrid-columnHeaders {
  border-radius: 6px 6px 0px 0px;
  background: rgba(196, 196, 196, 0.2);
}

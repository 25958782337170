.ld-sub-title.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(57, 57, 57, 0.7);
}

.sub-container {
  border: 0.2px solid #c4c4c4;
  border-radius: 3px;
  margin-top: 10px;
  padding: 10px;

  .MuiSelect-select {
    font-size: 12px;
  }
  .Mui-disabled {
    font-size: 12px;
  }
}

.sub-container-title.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #393939;
}

.sub-container-title-heading.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #393939;
}

.sub-container-key.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #393939;
}

.sub-container-value.MuiTypography-root {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(57, 57, 57, 0.7);
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}
.MuiFormControlLabel-label {
  font-size: 9px !important;
}
.switch-label-style {
  .MuiTypography-root {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgba(57, 57, 57, 0.7);
  }
}

.mt-85 {
  margin-top: 85px !important;
}

.gridContainer {
  width: 248px;
  background: rgba(57, 57, 57, 0.03);
  border-radius: 8px;
  padding: 10px;
  position: relative;
  cursor: pointer;

  h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393939;
    margin: 0;
    padding-bottom: 7px;
    white-space: nowrap;
    width: 166px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #393939;
    margin: 0;
    padding-bottom: 10px;
  }
  .timeContainer {
    min-width: 60px;
    padding: 5px;
    border: 0.4px solid #c4c4c4;
    border-radius: 3px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .category {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 19px;
    color: #c4c4c4;
  }
  .checkBoxContainer {
    position: absolute;
    right: 0px;
    top: 3px;
  }
}
.rightSection {
  display: flex;
  .status {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}

.sidebar-container {
  background: linear-gradient(163deg, #242634 0%, #11151B 100%);;
  padding-top: 20px;
  height: 100vh;
  width: 64px;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 10px 10px 0px;
  background: linear-gradient(163deg, #242634 0%, #11151B 100%);
}

.menu-items-container {
  margin-top: 30px;
  text-align: center;
}

.sidenav-icons {
  margin: 30px 0;
  cursor: pointer;
  opacity: 0.5;
}
.active {
  opacity: 1;
}
.sidenav-icons-separater{
  opacity: 0.5;
  border: 0.4px solid #fff;
  width: 44px;
}
.active-item {
  width: 4px;
  height: 32px;
  display: inline-block;
  position: absolute;
  left: 0;
  background-color: #fff;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

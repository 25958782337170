.toolbar-wrapper {
    display: flex;
    height: 100%;
}

.section-separator {
    width: 0.5px;
    background-color: #c4c4c4;
    margin: 15px 8px;
}

.formatting-controls {
    height: 100%;
    overflow: hidden;
    transition: all 150ms ease-in;
    display: flex;
    justify-content: center;
}

.custom-editor  {
    height: 100%;
    .ql-container {
        height: 90%;
    }
    .ql-container.ql-snow {
        background-color: #fff;
        border: none;
        border-radius: 0 0 8px 8px;
    }

    .ql-snow.ql-toolbar {
        z-index: 1;
        position: sticky;
        top: 0;
        height: 35px;
    }

    .ql-editor {
        border: none;
        border-top: none;
        border-radius: 0 0 4px 4px;
        height: calc(100% - 50px);
        color: #393939;
        font-family: Inter, sans-serif;
        font-size: 14px;
        caret-color: #393939;
        overflow: scroll;
        padding: 20px;
    }

    .ql-editor.ql-blank::before {
        font-style: normal;
        font-family: 'Inter', sans-serif;
        left: 20px;
    }
}

.custom-editor-dark  {
    height: 100%;
    .ql-container {
        height: 90%;
    }
    .ql-container.ql-snow {
        background-color: #1D202D;
        border: none;
        border-radius: 0 0 8px 8px;
    }
   
    .ql-snow.ql-toolbar {
        z-index: 1;
        position: sticky;
        top: 0;
        height: 35px;
        background-color: #1D202D;
        
    }
    .ql-toolbar .ql-stroke {
        fill: none;
        stroke: #fff;
    }
    .ql-snow .ql-picker{
        color: #fff;
    }
    .ql-editor {
        border: none;
        border-top: none;
        border-radius: 0 0 4px 4px;
        height: calc(100% - 50px);
        color: #fff;
        font-family: Inter, sans-serif;
        font-size: 14px;
        caret-color: #fff;
        overflow: scroll;
        padding: 20px;
        p{
            color: #fff
        }
    }

    .ql-editor.ql-blank::before {
        font-style: normal;
        font-family: 'Inter', sans-serif;
        left: 20px;
    }
}
.notes-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 12px;
    color: #C4C4C4;
    font-family: Inter, sans-serif;
}

.ql-snow.ql-toolbar {
  background-color: #fff;
  display: flex;
  position: absolute;
  justify-content: space-between;
  margin-bottom: '30px';
  width: 100%;
  height: 55px;
  border-radius: 8px 8px 0 0;
}

#custom-toolbar.ql-snow.ql-toolbar {
  padding: 0 20px;
}

.toolbar-wrapper {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.section-separator {
  width: 0.5px;
  background-color: #c4c4c4;
  margin: 15px 8px;
}

.formatting-controls {
  height: 100%;
  overflow: hidden;
  transition: all 150ms ease-in;
  display: flex;
  justify-content: center;
}

.text-editor {
  .ql-container.ql-snow {
    background-color: #fff;
    border: none;
    min-height: 90px;
    border-radius: 0 0 8px 8px;
    height: auto !important;
  }

  .ql-editor {
    border: none;
    border-top: 0.5px solid #c4c4c4;
    border-radius: 0 0 4px 4px;
    max-height: 150px !important;
    padding-right: 60px;
    height: auto !important;
    color: #393939;
    font-family: Inter, sans-serif;
    font-size: 14px;
    caret-color: #393939;
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    font-family: 'Inter', sans-serif;
  }
}

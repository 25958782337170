.customModalCont {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;
  .MuiPaper-root{
    width: 100%;
  }
  .modalContent {
    border: none;
    outline: none;
  }
  &.zoom-transition {
    .margin-btm-0 {
      margin-bottom: 0 !important;
    }
  }
  &.notes-transition {
    width: 376px;
    display: flex;
    flex-direction: column;
    .MuiCard-root {
      width: 100%;
      position: relative;
    }
    .notes-header {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #42526e;
      display: inline-block;
      margin-right: 20px;
    }
    .save-btn {
      padding: 6px 18px !important;
      width: 90px !important;
      background: #5267ff !important;
      border-radius: 4px !important;
      margin-right: 20px !important;
      &:disabled {
        opacity: 0.4;
      }
    }
    .clear-btn {
      box-shadow: none;
    }
    .MuiFormControl-root {
      margin-bottom: 0 !important;
    }
    .MuiSelect-select {
      height: 36px;
    }
    .MuiFormLabel-root {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 14px;
      color: #393939;
    }
  }
}

.customPopup {
  font-family: 'Inter';
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  align-self: center;
  outline: none;
  * {
    outline: none;
  }

  .MuiCardContent-root {
    padding: 20px;
    position: relative;

    .customPopUpHeader {
      color: #42526e;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
    .MuiFormControl-root,
    .MuiFormControl-root {
      margin: 0 0 20px 0;
      width: 100%;
    }
    .customPopUpDropDownLabel {
      display: inline-block;
      padding-bottom: 10px;
      font-weight: 500;
      color: #393939;
      font-family: 'Inter';
      font-size: 14px;
    }
    .save-btn,
    .disable-btn {
      background: #5267ff;
      border-radius: 2px;
      font-size: 12px;
      color: #fff;
      font-family: 'Inter';
      cursor: pointer;
      text-transform: none;
    }
    .disable-btn {
      background: rgba(82, 103, 255, 0.5);
    }
    .clear-btn {
      color: #393939;
      font-size: 12px;
      background: none;
    }
    .clearBtn {
      color: #393939;
      font-size: 12px;
      background: none;
    }
  }
  .MuiSelect-select {
    font-size: 12px;
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 26px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.customPopUpDropdownMenu {
  font-size: 12px !important;
}
.onLivePop {
  width: 100%;
}
.react-time-picker__inputGroup {
  padding: 4px !important;
  min-height: 36px !important;
}

.react-time-picker__wrapper {
  border-radius: 4px;
  border: 1px solid #c1c1c1 !important;
}

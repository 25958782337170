.templates-container {
  font-family: 'Inter';
  display: flex;
  position: absolute;
  box-sizing: border-box;
  bottom: 20px;
  z-index: 1000;
  flex-direction: column;
  background-color: #ffffff;
  // opacity: 90%;
  color: black;
  margin: 0px 20px;
  // width: 93%;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 10px 30px 10px 10px;

  &.fade-in-up {
    animation: slideInUp 0.15s;
    transition: all 1s ease-in;
  }

  &.fade-out-down {
    transform: translate(0px, 120%);
    transition: transform 0.15s linear;
  }
}

.template-recommendation-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #393939;
}

.template-recommendation-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
}

.template-source {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #cce5ff;
}

.template-source-text {
  color: #393939;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.template-header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #42526e;
}

.template-close-button {
  box-sizing: border-box;
  position: absolute;
  top: -12px;
  right: -12px;
  border: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.template-button {
  padding: 4px 8px;
  border-radius: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #5267ff;
  cursor: pointer;

  &.pressed {
    color: #67c848;
    border: none;
    cursor: auto;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(120%);
  }
  to {
    transform: translateY(0);
  }
}

.container {
  background: #fff;
  padding: 20px;
  width: 567px;
  height: 402px;
  border-radius: 8px;
  position: relative;
  .popUpHeader {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #525f80;
    margin: 0px 0px 20px 0px;
  }
  .formRow {
    display: flex;
    justify-content: space-between;
    > div {
      width: 100%;
    }
  }
  .categories {
    width: 100%;
    height: 144px;
    background: rgba(82, 103, 255, 0.05);
    border: 1px dashed rgba(82, 103, 255, 0.3);
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .box1 {
    width: 154px;
    background: #ffffff;
    border: 0.5px solid #c4c4c4;
    border-radius: 8px;
    padding: 20px 10px 10px;
    text-align: center;
    box-sizing: border-box;
  }
  .customArea {
    :global {
      .transParentButton {
        background-color: transparent;
        border: 1px solid #5267ff;
        border-radius: 4px;
        color: #5267ff;
        min-width: 101px;
        margin-left: 20px;
      }
    }
  }
  .btnArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 20px;
    bottom: 20px;
    button {
      font-size: 18px;
      height: auto;
      padding: 8px;
      min-width: 74px;
      + button {
        margin-left: 16px;
        background: transparent;
        text-transform: capitalize;

        color: #393939;
      }
    }
  }
}
.inputMd {
  input {
    height: 52px;
    box-sizing: border-box;
    padding-left: 10px !important;
  }
}
.success {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 567px;
  height: 402px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    color: #393939;
  }
}

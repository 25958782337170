input[type="checkbox"]:checked + label::after {
    content: '';
    position: absolute;
    width: 1.2ex;
    height: 0.4ex;
    top: 0.9ex;
    left: 0.4ex;
    border: 3px solid #FFF;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
 }

 input[type="checkbox"] {
    line-height: 2.1ex;
 }

 input[type="checkbox"] {
     position: absolute;
     left: -999em;
 }

 input[type="checkbox"] + label {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    vertical-align: -25%;
    height: 2ex;
    width: 2ex;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 2px;
    margin-right: 0.5em;
 }

input[type="checkbox"]:checked  + label::before {
   content: "";
   display: inline-block;
   vertical-align: -25%;
   height: 2ex;
   width: 2ex;
   background-color:  #FF6F62;
   border: 1px solid transparent;
   border-radius: 2px;
   margin-right: 0.5em;
}
.container {
  margin-bottom: 20px;
  .requiredLabel{
    color:red;
  }
  .kbStatus{
    font-size: 12px !important;
    margin-top: 3px;
  }

  .fetchInfos{
    padding-right: 90px !important;
  }
  
  :global {

    .MuiFormHelperText-root {
      padding-bottom: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #393939;
    }
    .MuiOutlinedInput-root {
      width: 207%;
      padding-right: 0;
      input[type='text'] {
        border: 0.5px solid #c4c4c4;
        border-radius: 4px;
        height: 44px;
        box-sizing: border-box;
        padding-left: 20px !important;
        
      }
    }
    .inputWithAdornment{
      input[type='text'] {
        padding-right: 90px !important;      
      }
     
    }
    .MuiInputAdornment-positionEnd {
      position: absolute;
      right: 8px;
      .MuiTypography-root {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #5267ff;
        cursor: pointer;
      }
      .custom-input-adornment {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #5267ff;
        cursor: pointer;
      }
      .custom-input-adornment-blurred {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #a8b3ff;
        cursor: not-allowed;
      }
      
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}
.customSelect {
  margin-bottom: 20px;
  :global {
    .MuiAutocomplete-root {
      width: 100%;
      text-align: left;
    }
  }
  .selectLabel {
    display: inline-block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393939;
    padding-bottom: 10px;
  }
  &.md {
    :global {
      .MuiOutlinedInput-root {
        height: 44px;
      }
    }
  }
  &.sm {
    :global {
      .MuiOutlinedInput-root {
        height: 33px;
      }
    }
  }
}

.toxicity-container {
  width: 98.6%;
  height: 670px;
  background: #ffffff;
  border-radius: 6px;
}

.toxic-words-container {
  width: 565px;
  height: 536px;
  left: 120px;
  top: 188px;
  background: #fafafa;
  border-radius: 6px;
}

.approved-websites-container {
  width: 565px;
  height: 536px;
  left: 715px;
  top: 188px;
  background: #fafafa;
  border-radius: 6px;
}

.sub-section {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  justify-content: space-around;
}

.header {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #42526e;
  padding: 20px;
}

.searchBar {
  display: flex;
  padding: 30px;
  justify-content: center;
}

.sublist {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #393939;
  height: 400px;
  overflow: scroll;
  padding-top: 20px;
}

.delete {
  color: red;
  display: none;
}

.internalText {
  padding-bottom: 30px;
  padding-left: 20px;
  justify-content: space-between;
  text-align: center;
  display: flex;
  &:hover {
    color: #000;
    background: rgba(83, 104, 255, 0.1);
    height: 20px;
    text-align: center;
  }
  &:hover .icons {
    display: block;
    height: 20px;
    justify-content: center;
  }
}

.subText {
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #393939;
}
.icon {
  display: none;
}

.alert {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #ff4d00;
  padding-top: 5px;
}

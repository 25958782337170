.container {
  background: #fff;
  padding-top: 20px;
  margin-top: 20px;
  .tableContainer {
    padding: 20px 17px 94px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 60vh;
    overflow-y: auto;
    :global {
      .communtity-grid {
        width: 24.2%;
        box-sizing: border-box;
      }
    }
  }
  .topSection {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 0px;
    margin-top: 20px;
  }
  .rightSection {
    min-width: 250px;
    justify-content: flex-end;
    display: flex;
    position: relative;
    > div,
    > button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .refreshCont {
      position: absolute;
      left: -53px;
      top: 50%;
      cursor: pointer;
      transform: translate(0, -50%);
    }
  }
  .selectContainer {
    min-width: 150px;
    position: relative;
    :global {
      .MuiOutlinedInput-root {
        height: 44px;
      }
    }
    a {
      text-decoration: none;
      color: #393939;
    }
  }
  :global {
    .MuiSnackbarContent-root {
      background-color: red;
    }
  }
}
.uploadPopUp {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
}
.popUpContent {
  padding: 30px 20px;
  box-sizing: border-box;
  width: 464px;
  height: 199px;
  background: rgba(82, 103, 255, 0.05);
  border: 1px dashed rgba(82, 103, 255, 0.3);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.box {
  background: #fff;
  text-align: center;
  padding: 10px 20px;

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #393939;
    position: relative;
    &::after {
      content: ' ';
      position: absolute;
      bottom: -6px;
      width: 100px;
      height: 2px;
      background: #c4c4c4;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
}
.popUpHeader {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #42526e;
}
.btnGps {
  display: flex;
  justify-content: center;
  padding: 20px;
  button {
    margin-right: 20px;
    height: auto;
    padding: 10px;
    min-width: 106px;
    &:nth-child(2) {
      background: transparent;
      color: #393939;
    }
  }

  .clear-btn {
    background: transparent;
  }
}
.browse {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  button {
    width: 73px;
    height: auto;
    text-transform: capitalize;
    color: #5267ff;
    background: rgba(82, 103, 255, 0.1);
    border-radius: 2px;
    padding: 4px;
  }
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: rgba(82, 103, 255, 0.5);
  }
}
.dotMenu {
  display: flex;
  align-items: center;
}
.informationCont {
  width: 512px;
  height: 95vh;
  max-height: 666px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  .scroller {
    height: 93%;
    overflow-y: auto;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
  position: absolute;
  width: 93%;
  bottom: 16px;
  &.flexEnd {
    justify-content: flex-end;
  }
  .navigationFooter {
    display: flex;
    width: 50px;
    justify-content: space-between;
    .normalDot {
      width: 14px;
      height: 14px;
      cursor: pointer;
      display: block;
      border-radius: 50%;
      background: #d9d9d9;
    }
    .activeDot {
      width: 27px;
      height: 14px;
      background: #5267ff;
      border-radius: 80px;
    }
  }
  .dotIcon {
    cursor: pointer;
  }
  .footerRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
    }
    :global {
      .infoSaveBtn {
        margin-left: 20px;
        height: auto;
      }
    }
  }
}
.timeSlot {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  span {
    &.day {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.04em;
      color: #969696;
    }
  }
}
.timeZones {
  display: flex;
  align-items: center;

  .startTime {
    font-size: 14px;
    color: #393939;
    display: flex;
    width: 130px;
    align-items: center;
    text-align: center;
    position: relative;
    input {
      border: none;
      text-align: center;
      width: 100%;
    }
    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .endTime {
    font-size: 14px;
    color: #393939;
    display: flex;
    width: 130px;
    align-items: center;
    text-align: center;
    position: relative;
    input {
      border: none;
      text-align: center;
      width: 100%;
    }
    svg {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .endTime {
    margin-left: 20px;
  }
}
.slotLabel {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #393939;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 13px;
  }
  .editIcon {
    margin-left: 20px;
    align-items: center;
    svg {
      margin-left: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.availability {
  display: flex;
  align-items: center;
  min-width: 60px;
  .status {
    font-size: 14px;
    min-width: 44px;
    color: #969696;
  }
}
.day {
  min-width: 100px;
}
.success {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 567px;
  min-height: 600px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    color: #393939;
  }
}
.hidden {
  opacity: 0;
  position: absolute;
  z-index: 999;
  width: 100px;
  height: 40px;
}
.timeZoneCont {
  margin-bottom: 20px;
  label {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #393939;
    padding-bottom: 10px;
    display: inline-block;
    span.asteriks {
      position: absolute;
      top: 0;
      right: 0;
      color: red;
    }
  }
  > div {
    > div {
      min-height: 44px;
    }
  }
}
.uploadStatus {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: flex-end;
}
.errorLabel {
  color: red;
}
.stdButton {
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #5267ff;
  border-radius: 4px;
  height: 44px;
}
.snackbarCont {
  :global {
    .MuiSnackbarContent-root {
      background-color: red;
    }
  }
  &.successSnakc {
    :global {
      .MuiSnackbarContent-root {
        background-color: green;
      }
    }
  }
}
.selectedView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 44px;
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
}

.selectedView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 44px;
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
  margin-left: 20px;
}
.stdButton {
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #5267ff;
  border-radius: 4px;
  height: 44px;
  &.disableBtn {
    opacity: 0.3;
  }
}
.rightSection {
  display: flex;
  justify-content: flex-end;
  .refreshCont {
    left: -53px;
    top: 8px;
    position: relative;
    cursor: pointer;
    cursor: pointer;
  }
}
.settingsHeader {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #393939;
  margin-bottom: 10px;
  margin-top: 44px;
}
.configSettings {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  bottom: 30px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  width: 303px;
  height: 112px;
  border: 0.5px solid #c4c4c4;
  border-radius: 8px;
  padding: 15px 10px;

  .settingsText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #42526e;
    padding-left: 23px;
    margin: 0;
  }
}
.settingsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  .para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #393939;
    margin: 0;
    display: flex;
    align-items: center;
    svg {
      width: 13px;
      height: 13px;
      margin-right: 10px;
    }
    + .para {
      padding-top: 10px;
    }
  }
}

.gridContainer {
  width: 269px;
  background: #f6f7ff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  margin: 5px;
  h6 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393939;
    margin: 0;
    padding-bottom: 7px;
    white-space: nowrap;
    width: 166px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #393939;
    margin: 0;
    padding-bottom: 10px;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .timeContainer {
    min-width: 60px;
    padding: 5px;
    border: 0.4px solid #c4c4c4;
    border-radius: 3px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .category {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 19px;
    color: #c4c4c4;
  }
  .checkBoxContainer {
    position: absolute;
    right: 0px;
    top: 3px;
  }
}
.rightSection {
  display: flex;
  .status {
    position: absolute;
    right: 9px;
    top: 9px;
  }
}
.livePopupCont {
  width: 900px;
  height: 50vh;
  overflow-y: auto;
  left: 0px;
  top: 0px;
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 20px;
  position: relative;
  .scroller {
    height: 93%;
    overflow-y: auto;
  }
  h2 {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Inter';
  }
  .rightSectionHeader {
  }
  .header {
    .stdButton {
      height: 31px;
      padding: 6px 12px;
    }
  }
}
.rightSectionHeader {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  .box {
    width: 64px;
    height: 29px;
    background: rgba(82, 103, 255, 0.1);
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #5267ff;
    margin-left: 20px;
  }
  .textC {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #393939;
    margin-left: 26px;
  }
}
.itemContainer,
.integrationGrid {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  max-height: calc(100vh - 454px);
  overflow-y: scroll;
  align-items: flex-start;
}
.integrationGrid {
  justify-content: flex-start;
  :global {
    .communtity-grid {
      width: 22.7%;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .stdButton {
    margin-top: -13px;
  }
}
.pageHeader {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #525f80;
  margin-bottom: 18px;
}

.h3 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  margin: 0;
  padding-right: 10px;
}

.h4 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  padding-right: 10px;
  color: #42526E;
}

.confHeader {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 18px;
}
.liveBox {
  align-items: center;
  width: 44px;
  height: 25px;
  display: flex;
  background: rgb(103 200 72 / 20%);
  border-radius: 3px;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgb(103 200 72);
}
.box {
  width: auto;
  height: 29px;
  background: rgba(82, 103, 255, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  padding: 6px;
  color: #5267ff;
  box-sizing: border-box;
  margin-left: 10px;
  img {
    margin-right: 10px;
  }
}
.editContainer {
  position: absolute;
  right: 0px;
  top: 0px;
  svg {
    width: 24px;
  }
}
.snackbarCont {
  :global {
    .MuiSnackbarContent-root {
      background-color: red;
    }
  }
  &.successSnakc {
    :global {
      .MuiSnackbarContent-root {
        background-color: green;
      }
    }
  }
}

.customDayStyle {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: rgba(57, 57, 57, 0.8) !important;
  &:hover {
    background-color: rgba(196, 196, 196, 0.2) !important;
  }

  &.selected {
    background-color: rgba(82, 103, 255, 0.4) !important;
    color: rgba(57, 57, 57, 0.8) !important;
    border-radius: 4px !important;

    &.diffMonthSelected {
      background-color: rgba(82, 103, 255, 0.2) !important;
      color: #5b63a0 !important;
      opacity: 0.6 !important;
    }
  }
}
.react-datepicker__input-container {
  input {
    font-size: 12px;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgba(82, 103, 255, 0.4) !important;
  color: white;
  font-weight: bold;
}

.otherMonth {
  color: rgba(57, 57, 57, 0.8) !important;
  opacity: 0.2 !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
}
.includedDates {
  color: #393939 !important;
  opacity: 0.8 !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

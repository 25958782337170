.container {
  width: 1326px;
  height: 648px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.profileImage {
  width: 420px;
  height: 400px;
  align-items: center;
  padding: 10px;
  padding-top: 20px;
  margin-left: 20px;
}

.profileInformation {
  width: 390px;
  height: 372px;
}

.assignClients {
  /* margin-left: 10px; */
  padding: 10px;
  margin-left: 20px;
  width: 412px;
  height: 342px;
  border-radius: 8px;
  border: 2px solid #d2d2d2;
  background-color: #ffffff;
}

.agentClient {
  color: #023e8a;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  /* Text style for "Clients" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.saveProfile {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 402px;
  height: 372px;
}

.cancel {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid #20065f;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.cancelText {
  /* Style for "Cancel" */
  width: 69px;
  height: 17px;
  color: #20065f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.4px;
  line-height: 12px;
  text-align: center;
  margin-left: 35px;
  margin-top: 10px;
  /* Text style for "Cancel" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
}

.save {
  /* Style for "Rectangle" */
  width: 150px;
  height: 40px;
  margin-left: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #20065f;
  background-color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.4px;
  line-height: 12px;
  text-align: center;
  /* background-color: #20065f; */
}

.saveText {
  width: 124px;
  height: 17px;
  color: #20065f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.4px;
  line-height: 12px;
  text-align: center;
  margin-left: 13px;
  margin-top: 10px;

  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
}

.profile {
  /* Style for "Profile" */
  width: 65px;
  height: 22px;
  color: #20065f;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.16px;
  line-height: 16px;
  text-align: left;
  /* Text style for "Profile" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
}

.fullimage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.demoimage {
  width: 150px;
  height: 150px;
  background-color: #eeeaf6;
  border-radius: 100%;
}

.addPhoto {
  /* Style for "Add Photo" */
  padding-top: 10px;
  width: 68px;
  height: 19px;
  color: #525f80;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  /* Text style for "Add Photo" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-decoration: underline;
}

.agentText {
  /* Style for "Full Name" */
  /* width: 65px; */
  height: 19px;
  color: #393939;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  /* Text style for "Full Name" */
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.agentSpace {
  margin: 5px;
}

.agentInput {
  /* Style for "Rectangle" */
  width: 386px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
}

.form {
  width: 368px;
  height: 46px;
}

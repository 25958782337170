.stdButton {
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  border: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background: #5267ff;
  border-radius: 4px;
  height: 44px;
}
:global {
  .lightButton {
    background: rgba(82, 103, 255, 0.1);
    opacity: 0.8;
    border-radius: 4px;
    color: #5267ff;
  }
  .greyButton {
    background: rgba(32, 32, 32, 0.2);
    font-family: 'Inter';
    color: #707070;
    padding: 5px;
    height: auto;
    font-size: 12px;
  }
  .disableButton {
    opacity: 0.5;
  }
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
}

.small {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 8px;
}

.toggle-switch-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-control {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(150, 150, 150, 0.3);
  transition: 0.225s;
  border-radius: 30px;
}

.switch-control:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0;
  bottom: -3px;
  background-color: #969696;
  border-radius: 50%;
  transition: 0.225s;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
}

.switch-control:hover:before {
  box-shadow: 0 0 0 6px rgba(155, 160, 168, 0.15);
}

input:checked+.switch-control {
  background-color: rgba(121, 202, 150, 0.3);
}

input:checked+.switch-control:before {
  transform: translateX(14px);
  background-color: #67C848;
}

input:checked+.switch-control:hover:before {
  box-shadow: 0 0 0 6px rgba(63, 196, 181, 0.15);
}

.small .switch-control:before {
  height: 12px;
  width: 12px;
  bottom: -2px;
}

.small input:checked+.switch-control:before {
  transform: translateX(8px);
  background-color: #67C848;
}
.custom-toast {
    .MuiPaper-root.MuiSnackbarContent-root {
        background: #FFEDE5 !important;
        box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FF4D00;
    }
}
.add-icon {
  background: rgba(82, 103, 255, 0.1);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  margin-right: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-dot {
  height: 11px;
  width: 11px;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  right: 0;
  background: #5267ff;
}
.custumPopOver {
  button {
    font-size: 12px;
    font-family: 'Inter';
    + button {
      background-color: #5267ff !important;
    }
  }
  h6 {
    font-size: 16px;
    margin: 0;
    text-align: center;
    font-family: 'Inter';
    color: #525f80;
    margin-bottom: 10px;
  }
}

.profileText{
  display: flex;
  font-family: 'Inter', sans-serif;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.status-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

.user-status-container{
  display: flex;

  .status-icon{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 6px;
  }
}

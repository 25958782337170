.switch-label-style {
  padding-left: 20px;
}

.call-toText-card {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
}

.d-flex {
  display: flex !important;
  flex-direction: row !important;
}

.call-layout {
  display: flex;
  width: 100%;
  color: #393939;
  height: 290px;
  overflow: auto;
  margin-bottom: 20px;
  margin-top: 15px;
}

.call-box {
  width: 35%;
  border: 0.5px solid rgba(196, 196, 196, 0.5);
  border-radius: 8px;
  padding: 10px;
  position: relative;
  height: 268px;

  .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #393939;
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
  }

  .call-inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
  }
}

.ivr-box {
  width: 62%;
  border: 0.5px solid rgba(196, 196, 196, 0.5);
  border-radius: 8px;
  padding: 10px;
  position: relative;
  display: flex;

  .box-1-ivr {
    width: 25%;
    border-right: 0.5px solid rgba(196, 196, 196, 0.5);

    padding-right: 16px;
  }

  .box-2-ivr {
    width: 75%;
    position: relative;
    padding-left: 10px;
    overflow: auto;

    .heading {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #393939;
    }
  }
}

.non-editCard {
  width: 45%;
  height: 40px;
  margin-bottom: 50px;

  .item-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .content {
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .editable {
    background-color: #f6f7ff;
  }

  .content-margin-less {
    margin-top: 0;
  }
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slot-checkbox {
  position: relative !important;
  left: 0 !important;
}

.slot-name {
  background: #f6f7ff;
  border-radius: 4px;
  padding: 8px;
}

.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
}

.MuiOutlinedInput-input {
  padding: 5px !important;
}

.pointer-none {
  pointer-events: none;
}

input[type='text'] {
  border-radius: 4px;
  padding-left: 6px;
}

audio {
  height: 40px;
}

.MuiAutocomplete-inputRoot {
  padding: 0px !important;

  input {
    height: 30px !important;
  }
}

.input-number {
  width: 74%;
  height: 85%;
}

.editable-audio {
  background-color: #f6f7ff;
}

.show-faded-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  background: #c4c4c4;
  opacity: 0.6;
  left: 0;
}

.editable {
  audio::-webkit-media-controls-panel {
    background-color: #f6f7ff;
  }
}

.show-btn-ivr {
  display: flex;
  position: absolute;
  top: 0%;
  right: 0;
  pointer-events: all;
}

.disabled-background {
  background: #f6f6f6 !important;
  border: none !important;
}

.disableCheckbox {
  position: relative !important;
  left: 0 !important;
}
